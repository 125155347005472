@import "variables";
@import "framework/framework";
@import "../../node_modules/@fortawesome/fontawesome-pro/scss/fontawesome";
@import "../../node_modules/@fortawesome/fontawesome-pro/scss/brands";
@import "../../node_modules/@fortawesome/fontawesome-pro/scss/solid";

@font-face {
	font-family: 'Avenir';
	src: url('../../dist/font/AvenirLTStd-Black.woff2') format('woff2'),
			url('../../dist/font/AvenirLTStd-Black.woff') format('woff');
	font-weight: 900;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Avenir';
	src: url('../../dist/font/AvenirLTStd-Book.woff2') format('woff2'),
			url('../../dist/font/AvenirLTStd-Book.woff') format('woff');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

html {
	background: $black;
	font-family: 'Avenir', Helvetica Neue, Helvetica, Arial, sans-serif;
	font-size: 16px;
	line-height: 1;
	scroll-behavior: smooth;

	@include desktop-sm-down {
		font-size: 15px;
	}

	@include tablet-down {
		font-size: 14px;
	}

	@include phone-down {
		font-size: 13px;
	}

	@include phone-sm-down {
		font-size: 12px;
	}
}

body {
	color: $white;
}

a {
	color: $white;

	@include hover-focus {
		color: $white;
	}
}

.textured{
	position: relative;
	&::before{
		content: "";
		display: block;
		width: 100%;
		height: 100%;
		position: absolute;
			top: 0;
			left: 0;
		background-position: center;
		background-size: cover;
	}
	.content{
		position: relative;
	}
	&.wall{
		&::before{
			background-image: url('../../dist/img/bg-texture-wall.jpg');
			opacity: .2;
		}
	}
	&.leather{
		background-color: $pink;
		&::before{
			background-image: url('../../dist/img/bg-texture-leather.jpg');
			opacity: .1;
		}
	}
}

.has-bg-image{
	position: relative;
	.bg{
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
	.content{
		position: relative;
	}
}

.btn{
	display: inline-block;
	padding: 15px 30px;
	text-align: center;
	border: none;
	text-transform: uppercase;
	font-size: 16px;
	&.btn-red{
		background-color: $red;
		color: $white;
	}
	&.btn-small{
		font-size: 14px;
	}
	@include tablet-up{
		font-size: 18px;
	}
	@include hover-focus{
		opacity: .8;
		&.btn-small{
			font-size: 14px;
		}
	}
}

h2,h3,img,p{
	margin: 0;
}

h2,h3{
	letter-spacing: .25em;
}

p{
	font-size: 16px;
	line-height: 1.6;
}

.section-title{
	text-transform: uppercase;
	font-size: 22px;
	margin-bottom: 40px;
	font-weight: 400;
	@include phone-up{
		font-size: 42px;
	}
	@include tablet-up{
		font-size: 72px;
	}
}

.social{
	@include flex(row,center,center);
	gap: 20px;
	a{
		font-size: 20px;
	}
}

.slick-slider{
	padding: 0 30px;
	position: relative;
	max-width: 1200px;
	margin: 0 auto;
	
	.slick-list {
		margin: 0 -20px;
		.slick-slide {
			margin: 0 20px;
		}
	}

	.slick-arrow{
		background-color: transparent;
		border: none;
		position: absolute;
		top: calc(50% - 3px);
		z-index: 999;
		&.slick-prev{
			left: 0;
		}
		&.slick-next{
			right: 0;
		}
		img{
			width: 20px;
		}
	}
	@include tablet-up{
		padding: 0 60px;
		.slick-arrow{
			top: calc(50% - 7.5px);
			img{
				width: 40px;
			}
		}
	}
}

header {
	.content{
		padding: 20px 0;
		.container{
			@include flex(column,center,center);
			gap: 20px;
			.nav{
				padding: 0;
				list-style: none;
				@include flex(row,center,center);
				flex-wrap: wrap;
				gap: 6px;
				li{
					a{
						text-transform: uppercase;
						color: $pink;
						font-size: 14px;
						@include hover-focus{
							color: $white;
						}
					}
				}
				.menu-close{
					display: none;
					@include tablet-down{
						@include flex(row,center,center);
						padding: 0;
						width: 40px;
						height: 40px;
						font-size: 20px;
						color: $black;
						background-color: $pink;
						position: absolute;
						top: 0;
						right: 0;
						border: none;
					}
				}
				@include tablet-down{
					position: fixed;
					width: 100%;
					height: 100%;
					top: 0;
					left: 0;
					z-index: 9999;
					flex-direction: column;
					margin: 0;
					opacity: 0;
					transition: all .4s;
					pointer-events: none;
					&.active{
						opacity: 1;
						pointer-events: all;
					}
					li{
						width: 100%;
						a{
							text-align: center;
							padding: 20px;
							font-size: 18px;
							display: block;
						}
					}
					background-color: $black;
				}
			}
			.menu-trigger{
				display: none;
				@include tablet-down{
					display: block;
					width: 40px;
					height: 30px;
					background-color: transparent;
					border: solid 2px transparent;
					border-top-color: $white;
					border-bottom-color: $white;
					margin: 0 auto 20px;
					position: relative;
					&::after{
						content: "";
						display: block;
						background-color: $white;
						width: 100%;
						height: 2px;
						position: absolute;
						top: calc(50% - 1px);
						left: 0;
					}
				}
			}
			.social{
				a{
					color: $pink;
					@include hover-focus{
						color: $white;
					}
				}
			}
		}
	}

	@include tablet-up{
		.content{
			.container{
				.nav{
					gap: 20px;
					li{
						a{
							font-size: 18px;
						}
					}
				}
			}
		}
	}
	
	@media screen and (min-width:1310px) {
		.content{
			.container{
				flex-direction: row;
				justify-content: space-between;
				.wrapper{
					@include flex(row,space-between,center);
					gap: 40px;
					.nav{
						li{
							a{
								font-size: 22px;
								font-weight: 700;
							}
						}
					}
				}
			}
		}
	}
}

main {

	section{
		.content{
			padding: 40px 0;
		}

		@include tablet-up{
			.content{
				padding: 80px 0;
			}
		}

		&.videos{
			.content{
				.container{
					.section-title{
						text-align: center;
						color: $black;
					}
					.featured-video{
						width: 100%;
						max-width: 1080px;
						aspect-ratio: 16 / 9;
						margin-bottom: 20px;
						margin: 0 auto 20px;
					}
					.slider-videos{
						.video-item{
							cursor: pointer;
						}
					}
				}
			}
		}

		&.music{
			.content{
				.container{
					.section-title{
						text-align: center;
					}
					.slider-music{
						.music-item{
							@include flex(column,center,center);
							gap: 20px;
							.music-top{
								position: relative;
								img{
									width: 100%;
								}
								.music-links{
									@include flex(row,space-between,initial);
									flex-wrap: wrap;
									position: absolute;
									bottom: 0;
									left: 0;
									width: 100%;
									opacity: 0;
									pointer-events: none;
									transition: all .4s;
									&.active{
										opacity: 1;
										pointer-events: all;
									}
									a{
										width: 50%;
										background-color: $red;
										color: $white;
										display: block;
										padding: 15px 10px;
										text-align: center;
										text-transform: uppercase;
										font-size: 14px;
										border-right: solid 1px $white;
										border-bottom: solid 1px $white;
										@include hover-focus{
											color: $black;
										}
										&:nth-child(even){
											border-right: none;
										}
										&:nth-child(n+5){
											border-bottom: none;
										}
									}
								}
							}
						}
					}
				}
			}
		}

		&.bio{
			.bg{
				object-position: right;
			}
			.content{
				.container{
					.wrapper{
						background-color: rgba(31, 33, 35, 0.7);
						padding: 30px;
						p + p{
							margin-top: 30px;
						}
						img{
							margin-bottom: 20px;
							margin: 0 auto 20px;
						}
						.btn{
							margin-top: 40px;
							@include tablet-down{
								display: block;
							}
						}
					}
				}
			}

			.popup-bio{
				position: fixed;
				width: 100%;
				height: 100%;
				z-index: 999999;
				top: 0;
				left: 0;
				transition: all .4s;
				opacity: 0;
				pointer-events: none;
				background-color: rgba(31, 33, 35, 0.9);
				&.active{
					opacity: 1;
					pointer-events: all;
				}
				.fb-container{
					padding: 40px 15px;
					@include flex(column,center,center);
					.fb-wrapper{
						background-color: $black;
						position: relative;
						padding: 40px 0;
						max-width: 800px;
						max-height: calc(100vh - 80px);
						.close{
							position: absolute;
							top: 0;
							right: 0;
							width: 40px;
							padding: 0;
							height: 40px;
							border: none;
							background-color: $pink;
							color: $black;
							@include flex(column,center,center);
						}
						.full-bio{
							max-height: calc(100vh - 160px);
							overflow-Y: scroll;
							padding: 0 40px 0 40px;
							p{
								margin-bottom: 30px;
							}
						}
					}
				}
			}
			
			@include tablet-up{
				.fb-container{
					height: 100vh;
				}
				.content{
					padding: 80px 0;
					.container{
						.wrapper{
							img{
								margin-bottom:40px;
							}
							max-width: 40%;
							padding: 40px;
						}
					}
				}
			}
		}

		&.tour{
			.content{
				.container{
					.wrapper{
						background-color: rgba(31, 33, 35, 0.7);
						padding: 30px;
						.btn{
							@include tablet-down{
								display: block;
							}
						}
						.section-title{
							color: $pink;
							text-align: center;
						}
						#events{
							@include flex(column,center,center);
							gap: 40px;
							.event{
								@include flex(column,center,center);
								gap: 10px;
								text-align: center;
								text-transform: uppercase;
								.event-links{
									@include flex(row,center,center);
									gap: 10px;
								}
								&:nth-child(n + 10){
									display: none;
								}
							}
							margin-bottom: 40px;
							&.all{
								.event{
									&:nth-child(n + 10){
										display: flex;
									}
								}
							}
						}
					}
				}
			}
			
			@include tablet-up{
				.content{
					padding: 80px 0;
					.container{
						@include flex(row,flex-end,flex-start);
						min-height: 1200px;
						.wrapper{
							width: 50%;
							padding: 40px;
							.section-title{
								text-align: left;
							}
							#events{
								.event{
									text-align: left;
									width: 100%;
									flex-direction: row;
									font-size: 17px;
									gap: 20px;
									.event-date{
										flex: 1;
										max-width: max-content;
									}
									.event-venue{
										flex: 1;
									}
									.event-location{
										flex: 1;
									}
									.event-links{
										flex: 1;
										min-width: 205px;
										max-width: 205px;
										justify-content: flex-end;
									}
								}
							}
						}
					}
				}
			}
		}

		&.instagram{
			.content{
				.container{
					.section-title{
						text-align: center;
					}
					#instagram-posts {
						max-width: 1200px;
						margin: 0 auto;
						display: flex;
						flex-direction: row;
						flex-wrap: wrap;
			
						> div {
							flex: 0 0 25%;
							padding: 10px;
			
							@include phone-down {
								flex: 0 0 50%;
							}
			
							> a {
								display: block;
								height: 100%;
								opacity: 1;
								width: 100%;
			
								> img {
									height: 100%;
									object-fit: cover;
									object-position: center center;
									width: 100%;
									aspect-ratio: 1 / 1;
								}
			
								@include hover-focus {
									opacity: 0.8;
								}
							}
						}
					}
				}
			}

			@include tablet-up{
				.content{
					padding: 80px 0;
				}
			}
		}

	}

}

footer {
	color: $black;
	.content{
		padding: 40px 0;
		.container{
			@include flex(column,initial,initial);
			gap: 60px;
			.newsletter{
				.form-notif{
					padding: 10px;
					text-align: center;
					color: $white;
					margin-top: 20px;
					display: none;
					&.form-success{
						background-color: green;
					}
					&.form-error{
						background-color: $red;
					}
				}
				form{
					h3{
						text-align: center;
						font-size: 32px;
					}
					.hidden{
						display: none
					}
					label{
						position: absolute;
						z-index: -99999999;
					}
					@include flex(column,initial,initial);
					gap: 20px;
					p{
						font-size: 12px;
						text-align:center;
					}
					> .form-row{
						@include flex(column,initial,initial);
						gap: 20px;
					}
					input[type="text"],
					input[type="email"],
					input[type="number"]{
						background-color: transparent;
						border: solid 1px transparent;
						transition: all .4s;
						border-bottom: solid 1px $gray;
						width: 100%;
						padding: 10px;
						text-transform: uppercase;
						color: $black;
						font-weight: 400;
						@include hover-focus{
							outline: none;
							border-color: $gray;
						}
						&::placeholder{
							font-family: Arial, Helvetica, sans-serif;
							font-weight: 600;
						}
					}
					.btn{
						width: 100%;
					}
				}
			}

			.partners{
				h3{
					color: $red;
					text-align: center;
					text-transform: uppercase;
					font-size: 32px;
					margin-bottom: 20px;
				}
				.wrapper{
					@include flex(row,center,center);
					gap: 20px;
				}
			}

			.copyright{
				p{
					font-size: 12px;
					text-align: center;
					text-transform: uppercase;
					a{
						color: $black;
						@include hover-focus{
							columns: $red;
						}
					}
				}
			}
		}
	}

	@include tablet-up{
		.content{
			padding: 80px 0;
			max-width: 800px;
			margin: 0 auto;
			.container{
				gap: 80px;
				.newsletter{
					form{
						>.form-row{
							flex-direction: row;
							justify-content: space-between;
							align-items: center;
							>.form-col{
								flex: 1;
								@include flex(row,space-between,initial);
								gap: 20px;
								>.form-row{
									flex: 1;
									@include flex(column,initial,initial);
									gap: 20px;
								}
								&:last-child{
									max-width: max-content;
									.btn{
										max-width: max-content;
									}
								}
							}
						}
					}
				}
			}
		}
	}
}